import Layout from "../components/layout"
import Seo from "../components/seo"
import "./mystyles.scss"
import {useEffect, useState} from "react";
import  {isLoggedIn} from "../utils/auth.service";
import {navigate} from "gatsby";
import React from 'react';
import authHeader from "../utils/auth-header";
import toast, {Toaster} from "react-hot-toast";
import LoadingSpinner from "../components/loading-spinner";


const ChangePassword = ({ location }) => {

    const [password, setPassword] = useState("");
    const [oldPassword, setOldPassword] = useState("");
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {


        if (!isLoggedIn()) {
           return  navigate('/');
        }


    }, [])


    const handleChangePassword = (e) => {


        e.preventDefault();
        setLoading(true)

        try {

            setSubmitDisabled(true);
            setLoading(true);

            const requestOptions = {
                method: 'POST',
                headers: authHeader(),
                body: JSON.stringify({
                    current_password: oldPassword,
                    new_password: password,

                })
            };


            fetch(process.env.WASHCAR_API_URL + "/business/change-password", requestOptions)
                .then(response => response.json()) // parse JSON from request
                .then(resultData => {

                    setSubmitDisabled(false);
                    setLoading(false)

                    resultData.status === 'Error' ?  toast.error(resultData.alert.text) : toast.success(resultData.alert.text);





                }) // set data for the number of stars




        } catch (err) {
            console.log(err);
        }


    };


    return (
        <Layout>
            <Seo title="Paroles maiņa"/>
            <h1 className="title">Paroles maiņa</h1>

            <Toaster
                position="top-center"
                reverseOrder={false}
            />

            {loading ? <div className="pos-login"><LoadingSpinner /></div> : null}

               <div className={'columns'}>

                   <form onSubmit={handleChangePassword} className={'box column is-half'}>


                       <div className="field">
                           <label className="label">Pašreizējā parole</label>
                           <div className="control">
                               <input required className="input" type="password" value={oldPassword}
                                      onChange={(e) => setOldPassword(e.target.value)}/>
                           </div>
                       </div>

                       <div className="field">
                           <label className="label">Jaunā parole</label>
                           <div className="control">
                               <input required className="input" type="password" value={password}
                                      onChange={(e) => setPassword(e.target.value)}/>
                           </div>
                       </div>


                       <div className="field is-grouped">
                           <div className="control">
                               <button disabled={submitDisabled} type="submit" className="button is-link">Iestatīt jauno paroli
                               </button>
                           </div>
                       </div>

                   </form>

               </div>

        </Layout>
    )
}

export default ChangePassword